'use client';

import Image from '@components/Image';
import Link from '@components/Link';
import { Carousel, CarouselContent, CarouselItem } from '@components/ui';
import type {
    BrandBannerFragment,
    ContentfulImageFragment,
} from '@server/gql/graphql';
import Autoscroll from 'embla-carousel-auto-scroll';

type BrandBannerItemProps = ContentfulImageFragment & {
    locale?: string;
};

function BrandBannerItem({ locale, link, image, name }: BrandBannerItemProps) {
    if (!image?.url) return null;

    if (link?.slug) {
        return (
            <Link className="inline-flex" href={link.slug} locale={locale}>
                <Image
                    className="w-full object-contain"
                    src={image?.url}
                    widths={[]}
                    width={image.width ?? undefined}
                    height={image.height ?? undefined}
                    alt={image.title ?? name ?? ''}
                    priority
                />
                <span className="sr-only">{image.title ?? name}</span>
            </Link>
        );
    }

    return (
        <Image
            src={image?.url}
            widths={[]}
            alt={image.title ?? name ?? ''}
            priority
        />
    );
}

type BrandBannerProps = {
    banner?: BrandBannerFragment;
} & {
    locale?: string;
};

export default function BrandBanner({ banner, locale }: BrandBannerProps) {
    if (!banner?.bannerItemsCollection?.items?.length) return null;

    const items = [
        ...banner.bannerItemsCollection.items,
        ...banner.bannerItemsCollection.items,
    ];

    if (!items?.length) return null;

    return (
        <Carousel
            className="overflow-x-hidden border-b border-t border-black"
            opts={{
                loop: true,
                align: 'start',
            }}
            plugins={[
                Autoscroll({
                    speed: 0.4,
                }),
            ]}>
            <CarouselContent className="items-center py-2 lg:py-3">
                {items.map((item, index) => {
                    if (item?.__typename === 'ContentfulImage') {
                        const width = (item?.image?.width ?? 100) / 2;
                        const height = (item?.image?.height ?? 100) / 2;

                        return (
                            <CarouselItem
                                className="ml-10 !basis-[var(--width)] items-center"
                                style={{
                                    // @ts-expect-error expected
                                    '--width': width?.toString() + 'px',
                                    '--height': height?.toString() + 'px',
                                }}
                                key={item.sys.id + index}>
                                <BrandBannerItem locale={locale} {...item} />
                            </CarouselItem>
                        );
                    }

                    return null;
                })}
            </CarouselContent>
        </Carousel>
    );
}
