'use client';

import { LazyRichText } from '@app/_blocks/RichText/LazyRichText';
import Button from '@app/_components/Button/Button';
import { ContentfulImage } from '@app/_components/ContentfulImage';
import Link from '@app/_components/Link';
import { Heading } from '@app/_components/ui';
import type {
    ContentfulAssetFragment,
    FooterBannerFragment,
} from '@server/gql/graphql';

import { cn } from '@/lib/utils';

type FooterBannerContentProps = {
    className?: string;
    locale?: string;
    market: string;
    footerBanner?: FooterBannerFragment | null;
};

export const FooterBannerContent = ({
    className,
    footerBanner,
    market,
    locale,
}: FooterBannerContentProps) => {
    if (!footerBanner) {
        return null;
    }

    const { title, paragraph, link, linkText, desktopImage, mobileImage } =
        footerBanner;
    const linkSlug = link?.slug;

    return (
        <section
            className={cn(
                'flex grid-cols-2 flex-row items-stretch bg-black text-white max-md:flex-col md:grid xl:max-h-120',
                className,
            )}>
            <div className="flex flex-col place-content-center gap-6 px-4 py-8 max-md:text-sm md:w-fit md:place-self-center md:px-8 md:py-12 lg:gap-8">
                <div className="flex flex-col gap-3">
                    {title && (
                        <Heading as="h3" size={5}>
                            {title}
                        </Heading>
                    )}
                    {paragraph && (
                        <LazyRichText
                            content={paragraph}
                            locale={locale}
                            market={market}
                            className="text-white max-lg:text-sm [&>*>a]:text-white" // Makes sure the link color is white
                        />
                    )}
                </div>
                {linkSlug && linkText && (
                    <Button
                        type="button"
                        as={Link}
                        locale={locale}
                        href={linkSlug}
                        className="btn-outlined h-12 min-w-60 border-white px-4 text-white hover:bg-white hover:text-black max-md:w-full md:mr-auto">
                        {linkText}
                    </Button>
                )}
            </div>
            <ContentfulImage
                mobileImage={mobileImage as ContentfulAssetFragment}
                desktopImage={desktopImage as ContentfulAssetFragment}
                className="relative aspect-[13/8] h-full w-full object-cover max-md:-order-1 md:aspect-square lg:aspect-[9/4] xl:max-h-120"
                sizes="(max-width: 768px) 100vw, 50vw"
                loading="lazy"
            />
        </section>
    );
};
